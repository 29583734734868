// 管理者用のメッセージ確認ダイアログ

<template lang="pug">
el-dialog.message-confirm-dialog(
  title='メッセージの詳細',
  width='80%',
  :visible='visible',
  @update:visible='$emit("update:visible", $event)',
  @open='open'
)
  article(v-if='detail')
    h1 {{ detail.title }}
    p
      span 送信日時:
      | {{ detail.sentAt | jpDateTime }}
    p
      span 宛先:
      | {{ detail.userGroupName }}
    p
      span Eメール送信:
      | {{ detail.sendMail ? 'する' : 'しない' }}
    pre {{ detail.body }}

    footer
      el-button(@click='close') 閉じる
</template>

<script>
import getMessageApi from '@/api/admin/get-message'

export default {
  name: 'MessageConfirmDialog',

  data() {
    return {
      detail: null,
    }
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    // メッセージのID
    messageId: {
      type: Number,
    },
  },

  methods: {
    async open() {
      const response = await getMessageApi(this.messageId)
      if (!response.ok) return
      this.detail = response.payload
    },
    close() {
      this.$emit('update:visible', false)
    },
  },
}
</script>

<style lang="sass" scoped>
.message-confirm-dialog
  article
    h1
      font-size: 1.2rem
      border-bottom: 1px solid $color-1
    p
      font-size: 1rem
      margin-top: 1rem
      color: $grey-dark
      span
        color: darken($color-1, 10%)
        margin-right: 0.5rem
    pre
      margin-top: 1rem
      border: 1px solid $grey-light
      border-radius: 3px
      padding: 0.5rem
      font-size: 1rem
      white-space: pre-wrap
    footer
      margin-top: 1rem
</style>
