// 管理者向け。全てのメッセージを取得

import api from '../api'

const path = '/admin/messages'

export default () => {
  return api({
    url: path,
    auth: true,
  })
}
