// メッセージの管理

<template lang="pug">
el-card.admin-messages
  header
    .go-back(style='margin-bottom: 0.5rem')
      el-button(
        type='text',
        icon='el-icon-back',
        @click='$router.go(-1)'
      ) 戻る
    el-button(
      type='primary',
      icon='el-icon-circle-plus',
      @click='openModifyMessageDialog'
    ) メッセージを作成

  el-table.message-list(:data='mails')
    el-table-column(
      label='送信日時'
    )
      template(slot-scope='scope')
        span(v-if='scope.row.sentAt') {{ scope.row.sentAt | jpDate }}
        span(v-else) 下書き
    el-table-column(
      label='タイトル',
      prop='title'
    )
    el-table-column(
      label='あて先',
      prop='userGroupName'
    )
    el-table-column(
      label='開封数',
      width='120',
      align='center'
    )
      template(slot-scope='scope')
        template(v-if='scope.row.sentAt')
          a.read-num(@click='openMessageRecipientsDialog(scope.row.id)')
            span {{ scope.row.readNum }}
            span /
            span {{ scope.row.sentNum }}
        template(v-else)
          span ---
    el-table-column(
      align='right'
    )
      template(slot-scope='scope')
        template(v-if='scope.row.sentAt')
          el-button(
            @click='confirmMessage(scope.row.id)'
            size='mini',
            type='primary',
            plain
          ) 確認
        template(v-else)
          el-button(
            @click='modifyMessage(scope.row.id)',
            size='mini',
            type='success',
            plain
          ) 編集
          el-button(
            @click='removeMessage(scope.row.title, scope.row.id)'
            size='mini',
            type='danger',
            plain
          ) 削除

  // 編集用のダイアログ
  modify-message-dialog(
    :visible.sync='modifyMessageDialogVisible',
    :message-id='currentMessageId',
    @update-message='updateMessage',
    @send-message='sendMessage'
  )

  // 内容表示用のダイアログ
  message-confirm-dialog(
    :visible.sync='messageConfirmDialogVisible',
    :message-id='currentMessageId'
  )

  // 宛先のリストダイアログ
  message-recipients-dialog(
    :visible.sync='messageRecipientsDialogVisible',
    :message-id='currentMessageId'
  )
</template>

<script>
import getMessageListApi from '@/api/admin/get-message-list'
import removeMessageApi from '@/api/admin/remove-message'

import ModifyMessageDialog from '@/dialogs/modify-message-dialog'
import MessageConfirmDialog from '@/dialogs/message-confirm-dialog'
import MessageRecipientsDialog from '@/dialogs/message-recipients-dialog'

export default {
  name: 'AdminMessages',

  components: {
    ModifyMessageDialog,
    MessageConfirmDialog,
    MessageRecipientsDialog,
  },

  data() {
    return {
      mails: [],
      modifyMessageDialogVisible: false,
      messageConfirmDialogVisible: false,
      messageRecipientsDialogVisible: false,
      currentMessageId: null,
    }
  },

  created() {
    this.init()
  },

  methods: {
    async init() {
      const response = await getMessageListApi()
      if (!response.ok) return
      this.mails = response.payload.items
    },
    openModifyMessageDialog() {
      this.currentMessageId = null
      this.modifyMessageDialogVisible = true
    },
    modifyMessage(messageId) {
      this.currentMessageId = messageId
      this.modifyMessageDialogVisible = true
    },
    confirmMessage(messageId) {
      this.currentMessageId = messageId
      this.messageConfirmDialogVisible = true
    },
    openMessageRecipientsDialog(messageId) {
      this.currentMessageId = messageId
      this.messageRecipientsDialogVisible = true
    },
    async removeMessage(title, messageId) {
      try {
        const _title = title || 'このメッセージ'
        await this.$confirm(`${_title}を削除してもよろしいですか？`, '確認', {
          confirmButtonText: '削除',
          cancelButtonText: 'キャンセル',
          type: 'warning',
        })
      } catch (e) {
        // キャンセルが押された
        return
      }
      const response = await removeMessageApi(messageId)
      if (!response.ok) {
        this.$alert('削除できませんでした。', 'エラー', {
          type: 'error',
        })
      } else {
        this.$message({
          message: '削除しました。',
          type: 'success',
        })
        await this.init()
      }
    },
    updateMessage() {
      this.$message({
        message: 'メッセージを保存しました。',
        type: 'success',
      })
      this.init()
    },
    sendMessage() {
      this.$message({
        message: 'メッセージを送信しました。',
        type: 'success',
      })
      this.init()
    },
  },
}
</script>

<style lang="sass" scoped>
.admin-messages
  a.read-num
    cursor: pointer
</style>
