// 送信済みの宛先リストの表示

<template lang="pug">
el-dialog.message-recipients-dialog(
  title='宛先リスト',
  width='60%',
  :visible='visible',
  @update:visible='$emit("update:visible", $event)',
  @open='open'
)
  article(v-if='detail')
    p 既読数 {{ readNum }}名 / 送信数 {{ sentNum }}名

    el-table(
      :data='recipients',
      height='500'
    )
      el-table-column(
        label='番号'
      )
        template(slot-scope='scope')
          span {{ scope.row.role === "student" ? scope.row.loginName : scope.row.organizationNumber }}
      el-table-column(
        label='名前'
      )
        template(slot-scope='scope')
          template(v-if='scope.row.role === "student"')
            span {{ scope.row.userName }}
            span /
          span {{ scope.row.organizationName }}
      el-table-column(
        label='開封日時'
      )
        template(slot-scope='scope')
          span(v-if='scope.row.readAt') {{ scope.row.readAt | jpDateTime }}
          span(v-else) ---

    footer
      el-button(@click='close') 閉じる
</template>

<script>
import get from 'lodash.get'

import getMessageRecipientsApi from '@/api/admin/get-message-recipients'

export default {
  name: 'MessageRecipientsDialog',

  data() {
    return {
      detail: null,
    }
  },

  computed: {
    readNum() {
      return get(this.detail, 'readNum', 0)
    },
    sentNum() {
      return get(this.detail, 'sentNum', 0)
    },
    recipients() {
      return get(this.detail, 'items', [])
    },
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    messageId: {
      type: Number,
    },
  },

  methods: {
    async open() {
      const response = await getMessageRecipientsApi(this.messageId)
      if (!response.ok) return
      this.detail = response.payload
    },
    close() {
      this.$emit('update:visible', false)
    },
  },
}
</script>

<style lang="sass" scoped>
.message-recipients-dialog
  footer
    margin-top: 1rem
</style>
